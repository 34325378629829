<template>
  <div class="container">
    <div class="clip-img-container">
      <img src="/icon/clip.png" alt="클립" />
    </div>
    <Clips />
    <h1 class="thick-border">
      <div class="inner-white">
        나의 직장 생활은
        <br />
        <span class="large">어떨깡?</span>
      </div>
    </h1>
    <img class="main" src="/card/intro.png" alt="'먼저 퇴근하겠읍니다.'라고 말하는 과자" />
    <button class="cta thick-border" @click="startTest">
      <div class="inner-white">OPEN</div>
    </button>
    <div class="count">현재까지 {{numberWithCommas(count)}}명이 참여했습니다!</div>
  </div>
</template>
<script>
import Clips from "@/components/Clips.vue";
import firebase from "firebase/app";
import "firebase/firestore";
export default {
  name: "Home",
  components: {
    Clips
  },
  data() {
    return {
      count: 0
    };
  },
  created() {
    const docRef = this.$firestore.collection("user").doc("kkangbti");
    docRef.get().then(doc => {
      const { finishCount } = doc.data();
      console.log(finishCount);
      this.count = finishCount;
    });
  },
  methods: {
    startTest() {
      const increment = firebase.firestore.FieldValue.increment(1);
      const docRef = this.$firestore.collection("user").doc("kkangbti");
      docRef.update({ startCount: increment });
      this.$router.push({
        name: "Test"
      });
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
};
</script>
<style lang="scss" scoped>
h1 {
  font-size: 30px;
  text-align: center;
  font-weight: 400;
  text-shadow: 2px 2px 0 #afafaf;
  line-height: 70px;
  margin: 145px auto 40px;
  span.large {
    font-size: 64px;
    font-family: "ONE-Mobile-POP";
  }
}
img.main {
  margin-bottom: 30px;
}
.clip-img-container {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding: 0 40px;
  margin-bottom: -62px;
}
.count {
  margin-top: 20px;
  font-weight: bold;
}
</style>
