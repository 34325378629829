<template>
  <div id="clip-container">
    <div class="clip-inner" :style="{borderColor: `${border ? '#e2b6b5': 'transparent'}`}">
      <div class="clip" :style="{background: quizIndex >= 3 ? 'transparent' :'#ffffff'}"></div>
      <div class="clip" :style="{background: quizIndex >= 5 ? 'transparent' :'#d8b0f9'}"></div>
      <div class="clip" :style="{background: quizIndex >= 7 ? 'transparent' :'#fdefae'}"></div>
      <div class="clip" :style="{background: quizIndex >= 11 ? 'transparent' :'#b4eeb1'}"></div>
      <div class="clip" :style="{background: '#b0d1fa'}"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    quizIndex: {
      type: Number,
      default() {
        return 0;
      }
    }
  },
  data() {
    return {
      border: true
    };
  },
  created() {
    if (this.$route.name === "Test") {
      this.border = false;
    }
  }
};
</script>
<style lang="scss" scoped>
#clip-container {
  max-width: 375px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  .clip-inner {
    position: absolute;
    right: 0;
    top: 80px;
    border-top: 2px solid #e2b6b5;
    border-left: 2px solid #e2b6b5;
    border-bottom: 2px solid #e2b6b5;
    border-radius: 10px 0 0 10px;
    .clip {
      width: 20px;
      height: 50px;
      &:first-child {
        border-radius: 10px 0 0 0;
      }
      &:last-child {
        border-radius: 0 0 0 10px;
      }
    }
  }
}
</style>